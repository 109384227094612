import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, retry, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ShowNotifierService } from '../service/showNotifierService/showNotifierService.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router: Router,
    private showNotifierService: ShowNotifierService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!navigator.onLine) {
      this.showNotifierService.showAlerts('Network interrupted.', 401);
      // return throwError(new HttpErrorResponse({ error: 'Network interrupted.' }));
    }
    const user = this.authService.getCurrentUser();
    if (user) {
      if (req.headers.has("skip-auth")) {
        req = req.clone({
          setHeaders: {},
        });
      } else {
        req = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${user.token}`,
          },
        });
      }
    }

    let timeoutMilis = 30000

    if (req.urlWithParams.includes("import")) {
      timeoutMilis = 200000
    }

    return next.handle(req).pipe(
      timeout(timeoutMilis),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.showNotifierService.showAlerts('Token expired', 401);
          this.authService.clearCurrentUser();
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        } else if (error.message === 'Timeout has occurred') {
          this.showNotifierService.showAlerts('Request Timeout', 401);
        }
        return throwError(error);
      }),
    );
  }
}
