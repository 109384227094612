
import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './authentication/auth.guard';

export const Approutes: Routes = [
  {
    canActivate: [AuthGuard],
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'user-management',
        loadChildren: () => import('./screens/user-management-page/user-management-page.module').then(
          module => module.UserManagementPageModule,
        ),
      },
      {
        path: 'special-period',
        loadChildren: () => import('./screens/special-period/special-period.module').then(
          module => module.SpecialPeriodModule,
        ),
      },
      {
        path: 'location',
        loadChildren: () => import('./screens/location/location.module').then(
          module => module.LocationModule,
        ),
      },
      {
        path: 'car',
        loadChildren: () => import('./screens/car/car.module').then(
          module => module.CarModule,
        ),

      },
      {
        path: 'fee',
        loadChildren: () => import('./screens/fee/fee.module').then(
          module => module.FeeModule,
        ),
      },
      {
        path: 'order',
        loadChildren: () => import('./screens/order/order.module').then(
          module => module.OrderModule,
        ),
      },
      {
        path: 'static-page',
        loadChildren: () => import('./screens/static-page/static-page.module').then(
          module => module.StaticPageModule,
        ),
      },
      {
        path: 'provider',
        loadChildren: () => import('./screens/providers/provider.module').then(
          module => module.ProviderModule,
        ),
      },
      {
        path: 'system',
        loadChildren: () => import('./screens/system-settings/system-settings.module').then(
          module => module.SystemSettingsModule,
        ),
      },
      {
        path: '#',
        loadChildren: () => import('./screens/coming-soon/coming-soon.module').then(
          module => module.ComingSoonModule,
        ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./screens/login-page/login-page.module').then(
      module => module.LoginPageModule,
    ),
    data: {
      title: 'Login',
    },
  },
  {
    path: '**',
    redirectTo: 'login',
    data: {
      title: 'Login',
    },
  },
];
