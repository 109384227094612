import { ICurrentUser } from 'src/app/model/user';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/authentication/auth.service';
import { ShowNotifierService } from 'src/app/service/showNotifierService/showNotifierService.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserManagementService } from 'src/app/service/userManagement/user-management.service';
import { validateAllFormFields } from 'src/app/shared/utils/validation';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from '../constant/const';
import { Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss']

})
export class VerticalNavigationComponent implements OnInit {
  constructor(private auth: AuthService,
    private showNotifierService: ShowNotifierService,
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private authService: AuthService,
    private router: Router
  ) { }
  formGroup!: FormGroup;
  formUserProfile!: FormGroup;
  isSubmitted: boolean = false;
  showModal: boolean = false;
  isShowModalUserProfile: boolean = false;
  infoUser: ICurrentUser | null = null;
  currentUser: ICurrentUser | null = null;
  maxDate: Date = new Date();
  DATE_PICKER_FORMAT = DATE_PICKER_FORMAT;
  areaCodes$: Observable<string[]> = of([]);

  ngOnInit(): void {
    this.showNotifierService.hideAllNotifications();
    this.createForm();
    this.infoUser = this.auth.getCurrentUser();
    this.areaCodes$ = this.userManagementService.getAreaCodes().pipe(
      tap(codes => {
        if (codes.length) {
          this.formUserProfile.patchValue({
            areaCode: codes[0]
          });
        }
      })
    );
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'oldpassword': [null, Validators.required],
      'newpassword': [null, Validators.required],
      'confirmNewPassword': [null, Validators.required],
    });
    this.formUserProfile = this.formBuilder.group({
      'areaCode': [null, Validators.required],
      'email': [{ value: null, disabled: true }],
      'gender': [null],
      'birthday': [''],
      'membershipId': [{ value: null, disabled: true }],
      'name': [null, Validators.required],
      'phone': [null, [Validators.required, Validators.pattern('[0-9]{4,12}')]],
      'surName': [null, Validators.required],
    });
  }

  onUpdateUserProfile() {
    const { birthday, ...body } = this.formUserProfile.value;
    body.birthday = birthday ? formatDate(birthday, DATE_FORMAT, 'en') : '';
    body.email = this.currentUser?.email || '';
    body.membershipId = this.currentUser?.membershipId || '';
    body.receiveLatestNews = this.currentUser?.receiveLatestNews;

    this.userManagementService.updateCurrentUserInfo(body).subscribe({
      next: () => {
        this.showNotifierService.showAlerts('Update successfully!', 200);
        this.isShowModalUserProfile = false;
      },
      error: error => {
        let messages = error.error.errors;
        messages.forEach((message: { msg: string; }) => {
          this.showNotifierService.showAlerts(message.msg, error.status);
        });
      }
    });
  }

  openModal() {
    this.showModal = true;
  }

  openModalUserProfile() {
    this.formUserProfile.reset();
    this.isShowModalUserProfile = true;
    this.userManagementService.getCurrentUserInfo().subscribe({
      next: res => {
        this.currentUser = res;
        this.formUserProfile.patchValue({
          areaCode: res.areaCode,
          email: res.email,
          gender: res.gender,
          birthday: res.birthday ? new Date(res.birthday) : '',
          membershipId: res.membershipId,
          name: res.name,
          phone: res.phone,
          surName: res.surName,
        });
      },
      error: error => {
        this.formUserProfile.disable();
        let messages = error.error.errors;
        messages.forEach((message: { msg: string; }) => {
          this.showNotifierService.showAlerts(message.msg, error.status);
        });
      }
    });
    
  }
  
  onLogout() {
    this.authService.clearCurrentUser();
    this.router.navigate(['/login']);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      if (this.formGroup.value.newpassword == this.formGroup.value.confirmNewPassword) {
        delete this.formGroup.value.confirmNewPassword;
        this.formGroup.value.username = this.infoUser?.email;
        this.userManagementService.changePassword(this.formGroup.value).subscribe({
          next: response => {
            this.showNotifierService.showAlerts(String(response.msg), 200);
            this.showModal = false;
            this.formGroup.reset();
            this.isSubmitted = false;
          },
          error: error => {
            let messages = error.error.errors;
            messages.forEach((message: { msg: string; }) => {
              this.showNotifierService.showAlerts(message.msg, error.status);
            });
          }
        });
      } else {
        this.showNotifierService.showAlerts('Confirm New Password incorrect!', 400);
      }
    } else {
      validateAllFormFields(this.formGroup);
    }
  }
}
