import { RouteInfo } from './vertical-sidebar.metadata';
import { environment } from 'src/environments/environment';

export const ROUTES: RouteInfo[] = [
  {
    path: environment.subPath,
    title: 'User',
    icon: 'fas fa-user',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/user-management',
        title: 'User Management',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Special Period',
    icon: 'fas fa-calendar-alt',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/special-period/period-name',
        title: 'Period Name',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/special-period/public-holiday',
        title: 'Public Holiday',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Location',
    icon: 'fas fa-location-dot',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/location/region',
        title: 'Region',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/area',
        title: 'Area',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/city',
        title: 'City',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/rental-location',
        title: 'Rental Location',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/opening-hour',
        title: 'Opening Hour',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/location-limousine',
        title: 'Location (Limousine)',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/area-limousine',
        title: 'Area (Limousine)',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/location/region-limousine',
        title: 'Service Region (Limousine)',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Car',
    icon: 'fas fa-car',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/car/optional-items',
        title: 'Optional Items',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/car-model',
        title: 'Car Model',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/car-grade',
        title: 'Car Grade',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/car-grade-limousine',
        title: 'Car Grade (Limousine)',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/car-setting',
        title: 'Car Setting',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/rental-status',
        title: 'Car Rental Status',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/car/rate-card',
        title: 'Rate Card',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Fee',
    icon: 'fas fa-dollar-sign',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      // {
      //   path: '/fee/rules',
      //   title: 'Rules',
      //   icon: 'mdi mdi-checkbox-blank-circle-outline',
      //   class: '',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [],
      // },
      {
        path: '/fee/rental-fee-hk',
        title: 'Rental Fee (Rent a car) - Hong Kong',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/fee/rental-fee-taiwan',
        title: 'Rental Fee (Rent a car) - Taiwan',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/fee/rental-fee-japan',
        title: 'Rental Fee (Rent a car) - Japan',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/fee/car-with-driver',
        title: 'Rental Fee (Rent a car with driver)',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/fee/extra-fee',
        title: 'Extra Handling Fee',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Order',
    icon: 'fas fa-shipping-fast',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/order/order-list',
        title: 'Order List',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/order/calendar',
        title: 'Calendar',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/order/promotion-code',
        title: 'Promotion Code',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Static Page',
    icon: 'fas fa-file-alt',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/static-page/article',
        title: 'Article',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      // {
      //   path: '#',
      //   title: 'Service',
      //   icon: 'mdi mdi-checkbox-blank-circle-outline',
      //   class: '',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [],
      // },
      // {
      //   path: '#',
      //   title: 'Booking',
      //   icon: 'mdi mdi-checkbox-blank-circle-outline',
      //   class: '',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [],
      // },
      {
        path: '/static-page/about-us',
        title: 'About Us',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/contact-us',
        title: 'Contact Us',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/van-logistics',
        title: 'Van Logistics',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/advertising-truck',
        title: 'Advertising Truck',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/long-term-leasing',
        title: 'Long Term Leasing',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/disclaimer',
        title: 'Disclaimer',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/term-of-use',
        title: 'Term of Use',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      // {
      //   path: '',
      //   title: 'Long Term Leasing',
      //   icon: 'fas fa-file-alt',
      //   class: 'has-arrow',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [
      //     {
      //       path: '/static-page/corporate',
      //       title: 'Corporate',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //     {
      //       path: '/static-page/individual',
      //       title: 'Individual',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        path: '/static-page/car-share',
        title: 'Car Share',
        icon: 'fas fa-file-alt',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/rent-a-car',
        title: 'Rent a Car',
        icon: 'fas fa-file-alt',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      {
        path: '/static-page/limousine-service',
        title: 'Limousine Service',
        icon: 'fas fa-file-alt',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
      // {
      //   path: '',
      //   title: 'Car Share',
      //   icon: 'fas fa-file-alt',
      //   class: 'has-arrow',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [
      //     {
      //       path: '/static-page/aprongo',
      //       title: 'Aprongo',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //     {
      //       path: '/static-page/property',
      //       title: 'Property',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //   ],
      // },
      // {
      //   path: '',
      //   title: 'RAC/Limo',
      //   icon: 'fas fa-file-alt',
      //   class: 'has-arrow',
      //   extralink: false,
      //   label: '',
      //   labelClass: '',
      //   submenu: [
      //     {
      //       path: '/static-page/rent-a-car',
      //       title: 'Rent A Car',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //     {
      //       path: '/static-page/oversea',
      //       title: 'Oversea',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //     {
      //       path: '/static-page/limousine',
      //       title: 'HK Limousine',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [
      //       ],
      //     },
      //     {
      //       path: '/static-page/limousine-china',
      //       title: 'China Limousine',
      //       icon: 'mdi mdi-checkbox-blank-circle-outline',
      //       class: 'pl-3',
      //       extralink: false,
      //       label: '',
      //       labelClass: '',
      //       submenu: [],
      //     },
      //   ],
      // }
    ],
  },
  {
    path: '',
    title: 'Providers',
    icon: 'fas fa-car',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '/provider/rental-provider',
        title: 'Rental Providers',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: 'system',
    title: 'System Settings',
    icon: 'fas fa-solid fa-cog',
    class: 'has-arrow',
    extralink: false,
    label: '',
    labelClass: '',
    submenu: [
      {
        path: '',
        title: 'Localization',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [
          {
            path: '/system/languages',
            title: 'Language Management',
            icon: 'fas fa-newspaper',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
          {
            path: '/system/regions',
            title: 'Region Management',
            icon: 'fas fa-location-arrow',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
          {
            path: '/system/airline',
            title: 'Airline Management',
            icon: 'fa-solid fa-plane-departure',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
          {
            path: '/system/currency',
            title: 'Currency Management',
            icon: 'fas fa-donate',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
        ],
      },
      {
        path: '/system/audit-logs',
        title: 'Supervision',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [
          {
            path: '/system/audit-logs',
            title: 'Audit logs',
            icon: 'fas fa-clipboard-list',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
          {
            path: '/system/third-party-service',
            title: 'Third party services',
            icon: 'fas fa-book',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
          {
            path: '/system/exchange-rate',
            title: 'Currency Exchange Rate',
            icon: 'fas fa-wallet',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
        ],
      },
      {
        path: '/system/dropdowns',
        title: 'Inception',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [
          {
            path: '/system/dropdowns',
            title: 'Dropdowns',
            icon: 'fas fa-solid fa-hand-holding-droplet',
            class: 'ml-3',
            extralink: false,
            label: '',
            labelClass: '',
            submenu: [],
          },
        ],
      },
      {
        path: '/system/emergency-message',
        title: 'Emergency Message',
        icon: 'mdi mdi-checkbox-blank-circle-outline',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [],
      },
    ],
  },
];
