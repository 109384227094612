export const environment = {
  "production": false,
  "subPath": "ml_website_cms",
  "api": "https://uat-api.dchml.com.hk/api/",
  "staticPath": "https://dch-motor-uat-motor-leasing-content-01.s3.ap-east-1.amazonaws.com/",
  "host": "0.0.0.0",
  "port": 3032,
  "secretKey": "DCH-MLS@2022",
  "webNewsUrl": "https://uat-www.dchml.com.hk/news/",
  "envName": "uat"
}
