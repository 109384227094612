import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLogin, UserChangePassword, IUserRow, ICurrentUser, IUserDetails } from 'src/app/model/user';
import { trim } from 'lodash';
import { IPermissionItem } from 'src/app/model/permission-menu';
import { IBaseService } from 'src/app/model/base-service';
import { IResponse } from 'src/app/model/response';
import { AREA_CODES } from 'src/app/shared/constant/area-code';
import { encryptStringValue } from 'src/app/shared/utils/common';
import { IDeleteData, IResponseStatus } from 'src/app/model/general';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root',
})
export class UserManagementService implements IBaseService {
  constructor(private http: HttpClient) { }

  login(user: UserLogin): Observable<ICurrentUser> {
    const url = environment.api + 'user/login';
    user = {
      ...user,
      username: encryptStringValue(user.username),
      password: encryptStringValue(user.password),
    };
    return this.http.post<ICurrentUser>(url, user, httpOptions);
  }

  list(page: number, offset: number, limit: number, search: string, multiSearch: string): Observable<IResponse<IUserRow>> {
    let url = `${environment.api}user/search?limit=${limit}&offset=${offset}&page=${page}`;
    url += trim(search) !== '' ? '&search=' + search : '';
    url += multiSearch ? `${multiSearch}` : '';
    return this.http.get<IResponse<IUserRow>>(url, httpOptions);
  }

  listAll(): Observable<IResponse<IUserRow>> {
    const url = environment.api + 'user/search';
    return this.http.get<IResponse<IUserRow>>(url, httpOptions);
  }

  changePassword(user: UserChangePassword): Observable<UserLogin> {
    const url = environment.api + 'user/changepassword';
    user = {
      ...user,
      oldpassword: encryptStringValue(user.oldpassword),
      newpassword: encryptStringValue(user.newpassword)
    };
    return this.http.post<UserLogin>(url, user, httpOptions);
  }

  pageMenu(): Observable<IPermissionItem[]> {
    const url = environment.api + 'menu/all';
    return this.http.get<IPermissionItem[]>(url, httpOptions);
  }

  create(data: IUserDetails): Observable<IResponseStatus> {
    const url = environment.api + 'user/register';
    data = {
      ...data,
      email: encryptStringValue(data.email),
    };
    return this.http.post<IResponseStatus>(url, data, httpOptions);
  }

  update(data: IUserDetails): Observable<IUserRow> {
    const url = environment.api + 'user/update';
    return this.http.post<IUserRow>(url, data, httpOptions);
  }

  userDetail(id: string): Observable<IUserDetails> {
    const url = environment.api + 'user?id=' + id;
    return this.http.get<IUserDetails>(url, httpOptions);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(data: IDeleteData): Observable<IResponseStatus> {
    throw new Error('Method is not implemented');
  }

  getAreaCodes(): Observable<string[]> {
    return of(AREA_CODES);
  }

  getCurrentUserInfo(): Observable<ICurrentUser> {
    const url = environment.api + 'user/my-info';
    return this.http.get<ICurrentUser>(url, httpOptions);
  }

  updateCurrentUserInfo(data: ICurrentUser): Observable<ICurrentUser> {
    const url = environment.api + 'user/my-info';
    return this.http.post<ICurrentUser>(url, data, httpOptions);
  }
}
