import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICurrentUser } from '../model/user';
import { Role } from '../shared/constant/role-permission';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) { }

  isAuthenticated = () => {
    return this.getCurrentUser() !== null;
  };

  getCurrentUser(): ICurrentUser | null {
    const encryptedUser = localStorage.getItem('currentUser') || '';
    if (encryptedUser) {
      const bytes = CryptoJS.AES.decrypt(encryptedUser, environment.secretKey);
      return JSON.parse(decodeURIComponent(bytes.toString(CryptoJS.enc.Utf8)));
    }
    return null;
  }

  clearCurrentUser() {
    localStorage.removeItem('currentUser');
  }

  getUserRole(): Role {
    const curUser = this.getCurrentUser();
    return curUser?.role || Role.EMPTY;
  }

  canEditData(): boolean {
    const curUser = this.getCurrentUser();
    return curUser?.role === Role.ADMIN || curUser?.role === Role.STAFF;
  }
}
