import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import * as customBuild from 'src/custom-ckeditor.js';

export const customCkEditor = customBuild;

export function validateAllFormFields(formGroup: UntypedFormGroup) {
  formGroup.markAllAsTouched();
  // Validate endDate
  const startDate = formGroup.get('startDate')?.value;
  const endDate = formGroup.get('endDate')?.value;
  return endDate >= startDate;
}

export function dateRangeValidator(fromDateField: string, toDateField: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const fromDateControl = formGroup.get(fromDateField);
    const toDateControl = formGroup.get(toDateField);

    const fromDate = fromDateControl?.value;
    const toDate = toDateControl?.value;
    if ((fromDate && toDate) && new Date(fromDate).getTime() > new Date(toDate).getTime()) {
      toDateControl?.setErrors({ 'invalidDateRange': true });
      toDateControl?.markAsTouched();
      return { 'invalidDateRange': true };
    } else if ((fromDate && toDate) && new Date(fromDate).getTime() <= new Date(toDate).getTime() && toDateControl?.hasError('invalidDateRange')) {
      toDateControl?.setErrors(null);
    }

    return null;
  };
}

export function spaceVaidator(control: AbstractControl) {
  if (control && control.value && !control.value.trim().length) {
    return { required: true };
  }
  return null;
}

export function urlValidator(mapUrl: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const fromUrlControl = formGroup.get(mapUrl);
    var pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (!pattern.test(fromUrlControl?.value)) {
      fromUrlControl?.setErrors({ 'invalidUrl': true });
      return { 'invalidUrl': true };
    } else {
      fromUrlControl?.setErrors(null);
    }
    return null;
  };
}
