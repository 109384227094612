import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { DefaultErrorMessage } from '../constant/error-message';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { DATE_TIME_FORMAT } from '../constant/const';

export function handleError(error: HttpErrorResponse): string[] {
  const errorObj: { [key: string]: string } = Array.isArray(error?.error.errors) ? error?.error.errors[0] : [DefaultErrorMessage.msg];
  let messages: string[] = [];
  Object.keys(errorObj).forEach(key => {
    if (typeof errorObj[key] === 'string') {
      messages.push(errorObj[key]);
    } else if (Array.isArray(errorObj[key])) {
      messages = [...messages, ...errorObj[key]];
    }
  });
  return messages;
}

export function convertErrorToMessage(errorMsg: string): Message {
  return {
    severity:'error',
    summary:'Error',
    detail: errorMsg
  };
}

export function trimObjectValues(data: { [key: string]: any }) {
  for (let key in data) {
    if (typeof data[key] === 'string') {
      data[key] = data[key].trim();
    }
  }
  return data;
}

export function encryptStringValue(value: string) {
  return CryptoJS.AES.encrypt(value, environment.secretKey).toString();
}

export function decryptStringValue(value: string) {
  return CryptoJS.AES.decrypt(value, environment.secretKey).toString(CryptoJS.enc.Utf8);
}

export function formatCurrentDateTime(date: string) {
  const dateFormat = formatDate(date, DATE_TIME_FORMAT, 'en');
  return dateFormat;
}
