<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <!-- <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li> -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu *ngIf="infoUser">
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
        <div class="">
          <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0">{{infoUser.name}} {{infoUser.surName}}</h4>
          <p class=" m-b-0">{{infoUser.email}}</p>
        </div>
      </div>
      <a class="dropdown-item" href="javascript:void(0)" (click)="openModalUserProfile()">
        <i class="ti-user fa-user m-r-5 m-l-5"></i>Profile</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)" (click)="openModal()">
        <i class="ti-settings m-r-5 m-l-5"></i>Change Password</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
      <div class="dropdown-divider"></div>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <p-dialog header="User Profile"  [modal]="true" [(visible)]="isShowModalUserProfile" [dismissableMask]="true">
    <div class="modal-body" *ngIf="formUserProfile">
      <form [formGroup]="formUserProfile" >
        <div class="form-body">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label l-required">First Name</label>
                  <input type="text" class="form-control"
                    formControlName="name" placeholder="First Name">
                  <div class="invalid-feedback">First Name is required</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group has-danger">
                  <label class="control-label l-required">Last Name</label>
                  <input type="text" class="form-control form-control-danger"
                    formControlName="surName" placeholder="Last Name">
                  <div class="invalid-feedback"> Last Name is required </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group m-b-30">
                  <label class="mr-sm-2" for="gender">Gender</label>
                  <select class="custom-select mr-sm-2" id="gender"
                    formControlName="gender">
                    <option [ngValue]=null disabled>Please select a gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group has-danger">
                  <label class="control-label">Birthday</label>
                    <p-calendar
                      [showIcon]="true"
                      placeholder="Birthday"
                      formControlName="birthday"
                      class="g-col-12"
                      styleClass="d-flex"
                      [maxDate]="maxDate"
                      [dateFormat]="DATE_PICKER_FORMAT"
                      ></p-calendar>
                </div>
              </div>
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group has-danger">
                  <label class="control-label l-required">Area Code</label>
                  <select class="custom-select mr-sm-2" id="areaCode"
                    formControlName="areaCode">
                    <option [ngValue]=null disabled>Please select an area code</option>
                    <option *ngFor="let code of areaCodes$ | async" [value]="code">
                      {{ code }}
                    </option>
                  </select>
              </div>
              </div>
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group has-danger">
                  <label class="control-label l-required">Mobile No.</label>
                  <input type="number" class="form-control form-control-danger" formControlName="phone"
                    placeholder="Mobile No.">
                  <div class="invalid-feedback"> Mobile No. invalid </div>
                </div>
              </div>
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group has-danger">
                  <label class="control-label l-required">Email</label>
                  <input type="email" class="form-control form-control-danger"
                    formControlName="email" placeholder="Email">
                </div>
              </div>
              <!--/span-->
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Membership ID</label>
                  <input type="text" class="form-control"
                    formControlName="membershipId" placeholder="Membership ID">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info" [disabled]="formUserProfile.invalid || formUserProfile.pristine" (click)="onUpdateUserProfile()">Update</button>
    </div>
  </p-dialog>
  <!-- ============================================================== -->
</ul>

<!-- model -->
<p-dialog header="Change Password" [modal]="true" [(visible)]="showModal" [dismissableMask]="true">
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div class="form-body">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label l-required">Old Password</label>
                <input type="password" formControlName="oldpassword" class="form-control" placeholder="Old Password">
                <div class="invalid-feedback"> Old Password invalid </div>
              </div>
            </div>
            <!--/span-->
            <div class="col-md-12">
              <div class="form-group has-danger">
                <label class="control-label l-required">New Password</label>
                <input type="password" formControlName="newpassword" class="form-control form-control-danger" placeholder="New Password">
                <div class="invalid-feedback"> New Password invalid </div>
              </div>
            </div>
            <!--/span-->
            <div class="col-md-12">
              <div class="form-group has-danger">
                <label class="control-label l-required">Confirm New Password</label>
                <input type="password" formControlName="confirmNewPassword" class="form-control form-control-danger" placeholder="Confirm New Password">
                <div class="invalid-feedback"> Confirm New Password invalid </div>
              </div>
            </div>
            <!--/span-->
          </div>
          <!--/row-->

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="onSubmit()">Save</button>
  </div>
</p-dialog>
